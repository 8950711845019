import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const socket = io(process.env.NODE_ENV === 'production' ? 'https://scoreboard.afc95570.fr' : 'http://localhost:5000');


const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

function Scoreboard() {
  const [scoreboard, setScoreboard] = useState({
    team1: { name: 'TEAM 1', logo: '', score: 0, fouls: 0 },
    team2: { name: 'TEAM 2', logo: '', score: 0, fouls: 0 },
    timer: 0,
    timerRunning: false,
    period: '1 MT'
  });

  useEffect(() => {
    socket.on('scoreboardUpdate', (data) => {
      setScoreboard(data);
    });

    return () => {
      socket.off('scoreboardUpdate');
    };
  }, []);

  const FoulIndicator = ({ fouls }) => (
    <div className="flex justify-center">
      {[...Array(5)].map((_, i) => (
        <div
          key={i}
          className={`w-2 h-2 rounded-full mx-0.5 ${
            i < fouls ? 'bg-yellow-400' : 'bg-gray-400'
          }`}
        />
      ))}
    </div>
  );

  return (
    <div className="font-roboto w-1/2">
      <div className="grid grid-cols-[11%_8.75%_4.5%_25%_6.25%_2.5%_6.25%_25%] grid-rows-2 h-[100px]">
        <div className="col-span-1 row-span-2 bg-[#cfd71d] flex justify-center items-center">
          <img src="/images/R1.png" alt="R1" className="max-w-full max-h-full object-contain" />
        </div>
        <div className="col-span-2 bg-[#dfdfdf] text-[#11217a] font-bold uppercase flex justify-center items-center text-lg">
          {formatTime(scoreboard.timer)}
        </div>
        <div className="bg-[#1e487e] text-white font-bold uppercase flex justify-center items-center">
          {scoreboard.team1.name}
        </div>
        <div className="bg-[#1e487e] text-[#cfd71d] font-bold uppercase flex justify-center items-center text-2xl">
          {scoreboard.team1.score}
        </div>
        <div className="bg-[#1e487e] text-[#cfd71d] font-bold uppercase flex justify-center items-center text-2xl">
          -
        </div>
        <div className="bg-[#1e487e] text-[#cfd71d] font-bold uppercase flex justify-center items-center text-2xl">
          {scoreboard.team2.score}
        </div>
        <div className="bg-[#1e487e] text-white font-bold uppercase flex justify-center items-center">
          {scoreboard.team2.name}
        </div>
        <div className="col-span-2 bg-[#1e487e] text-white font-bold uppercase flex justify-center items-center">
          {scoreboard.period}
        </div>
        <div className="mt-2">
          <FoulIndicator fouls={scoreboard.team1.fouls} />
        </div>
        <div className="col-span-3"></div>
        <div className="mt-2">
          <FoulIndicator fouls={scoreboard.team2.fouls} />
        </div>
      </div>
    </div>
  );
}

export default Scoreboard;

