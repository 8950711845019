import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const socket = io(process.env.NODE_ENV === 'production' ? 'https://scoreboard.afc95570.fr' : 'http://localhost:5000');


function Admin() {
  const [scoreboard, setScoreboard] = useState({
    team1: { name: 'Team 1', logo: '', score: 0, fouls: 0 },
    team2: { name: 'Team 2', logo: '', score: 0, fouls: 0 },
    timer: 0,
    timerRunning: false,
    period: '1 MT'
  });

  useEffect(() => {
    socket.on('scoreboardUpdate', (data) => {
      setScoreboard(data);
    });

    return () => {
      socket.off('scoreboardUpdate');
    };
  }, []);

  const handleChange = (team, field, value) => {
    const updatedScoreboard = { ...scoreboard };
    updatedScoreboard[team][field] = value;
    setScoreboard(updatedScoreboard);
    socket.emit('updateScoreboard', updatedScoreboard);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const confirmReset = () => {
    if (window.confirm('Voulez vous vraiment réinitialiser le Scoreboard ?')) {
      resetScoreboard();
    }
  };

  const removeLogo = (team) => {
    handleChange(team, 'logo', '');
  };

  const handleTimerChange = (value) => {
    const updatedScoreboard = { ...scoreboard, timer: value };
    setScoreboard(updatedScoreboard);
    socket.emit('updateScoreboard', updatedScoreboard);
  };

  const toggleTimer = () => {
    const updatedScoreboard = { ...scoreboard, timerRunning: !scoreboard.timerRunning };
    setScoreboard(updatedScoreboard);
    socket.emit('updateScoreboard', updatedScoreboard);
  };

  const handlePeriodChange = (period) => {
    const updatedScoreboard = { ...scoreboard, period };
    setScoreboard(updatedScoreboard);
    socket.emit('updateScoreboard', updatedScoreboard);
  };

  const resetScoreboard = () => {
    const resetScoreboard = {
      team1: { name: 'Team 1', logo: '', score: 0, fouls: 0 },
      team2: { name: 'Team 2', logo: '', score: 0, fouls: 0 },
      timer: 0,
      timerRunning: false,
      period: '1 MT'
    };
    setScoreboard(resetScoreboard);
    socket.emit('updateScoreboard', resetScoreboard);
  };
  

  const handleLogoUpload = async (team, file) => {
    const formData = new FormData();
    formData.append('logo', file);

    try {
      const response = await fetch(`http://localhost:5000/upload/${team}`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        handleChange(team, 'logo', data.logoUrl);
      } else {
        console.error('Upload failed');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Scoreboard Admin</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {['team1', 'team2'].map((team) => (
          <div key={team} className="bg-gray-100 p-4 rounded">
            <h2 className="text-2xl font-bold mb-2">{scoreboard[team].name}</h2>
            <input
              type="text"
              value={scoreboard[team].name}
              onChange={(e) => handleChange(team, 'name', e.target.value)}
              className="w-full p-2 mb-2 border rounded"
            />
            <div className="mb-2">
              <label className="block mb-1">Logo:</label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleLogoUpload(team, e.target.files[0])}
                className="w-full p-2 border rounded"
              />
            </div>
            {scoreboard[team].logo && (
  <div>
    <img src={scoreboard[team].logo} alt="Team Logo" className="w-24 h-24 object-contain mb-2" />
    <button onClick={() => removeLogo(team)} className="bg-red-500 text-white px-2 py-1 rounded">Supprimer le Logo</button>
  </div>
)}
            <div className="flex items-center mb-2">
              <span className="mr-2">Score:</span>
              <button onClick={() => handleChange(team, 'score', Math.max(0, scoreboard[team].score - 1))} className="bg-red-500 text-white px-2 py-1 rounded">-</button>
              <span className="mx-2">{scoreboard[team].score}</span>
              <button onClick={() => handleChange(team, 'score', scoreboard[team].score + 1)} className="bg-green-500 text-white px-2 py-1 rounded">+</button>
            </div>
            <div className="flex items-center">
              <span className="mr-2">Fouls:</span>
              {[...Array(5)].map((_, i) => (
                <button
                  key={i}
                  onClick={() => handleChange(team, 'fouls', i + 1)}
                  className={`w-6 h-6 rounded-full mx-1 ${i < scoreboard[team].fouls ? 'bg-yellow-400' : 'bg-gray-400'}`}
                ></button>
              ))}
              <button 
                onClick={() => handleChange(team, 'fouls', Math.max(0, scoreboard[team].fouls - 1))}
                className="bg-red-500 text-white px-2 py-1 rounded ml-2"
              >
                -
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-4">
  <h2 className="text-2xl font-bold mb-2">Timer</h2>
  <div className="flex items-center">
    <span className="mr-2">{formatTime(scoreboard.timer)}</span>
    <input
      type="number"
      value={scoreboard.timer}
      onChange={(e) => handleTimerChange(Math.max(0, parseInt(e.target.value)))}
      className="w-24 p-2 border rounded mr-2"
    />
    <button
      onClick={toggleTimer}
      className={`px-4 py-2 rounded ${scoreboard.timerRunning ? 'bg-red-500' : 'bg-green-500'} text-white`}
    >
      {scoreboard.timerRunning ? 'Pause' : 'Play'}
    </button>
  </div>
</div>
      <div className="mt-4">
        <h2 className="text-2xl font-bold mb-2">Periode</h2>
        <select 
          value={scoreboard.period}
          onChange={(e) => handlePeriodChange(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="1 MT">1iere Mi-Temps</option>
          <option value="2 MT">2eme Mi-Temps</option>
        </select>
      </div>
      <button 
  onClick={confirmReset}
  className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
>
  Reset Scoreboard
</button>
    </div>
  );
}

export default Admin;

